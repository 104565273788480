<template>
  <div>
    <b-card>
      <b-row class="justify-content-end">
        <b-col
          md="auto"
        >
          <b-button
            variant="primary"
            @click="newFlashInfo()"
          >
            Nouvelle flash info
          </b-button>
        </b-col>
      </b-row>
      <CustomFilter
        prop-module-name="flash info"
      />
      <b-row class="justify-content-end">
        <b-col
          md="auto"
        >
          <b-button
            variant="primary"
            @click="onSearch"
          >
            Rechercher
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          nextLabel: 'Suivant',
          prevLabel: 'Précédent',
          rowsPerPageLabel: 'Lignes par page'
        }"
        class="table b-table b-table-fixed"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field == 'update'"
          >
            <b-button
              id="detailIcon"
              variant="primary"
              @click="showModal(props.row)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
            <!-- <ModalUpdate /> -->
          </span>
          <span v-else-if="props.column.field == 'actif'">
            <b-badge
              v-if="props.formattedRow[props.column.field]"
              variant="success"
            >
              Actif
            </b-badge>
            <b-badge
              v-else
              variant="danger"
            >
              Inactif
            </b-badge>
          </span>
          <span v-else-if="props.column.field == 'hasFile'">
            <b-badge
              v-if="props.formattedRow[props.column.field]"
              variant="success"
            >
              Oui
            </b-badge>
            <b-badge
              v-else
              variant="danger"
            >
              Non
            </b-badge>
          </span>
          <span v-else-if="props.column.field == 'apercu'">
            <b-img
              style="width: 80px"
              :src="props.row['piece_jointe']"
              @click="openImage(props.row['piece_jointe'])"
            />
          </span>
        </template>
      </vue-good-table>
    </b-card>

    <b-modal
      id="modal-update"
      v-model="modalShow"
      centered
      size="lg"
      :title="modalTitle"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="secondary"
      @ok="submitForm"
    >
      <validation-observer ref="observer">
        <b-form>
          <b-form-group
            label="Libellé"
          >
            <validation-provider
              #default="{ errors }"
              name="libelle"
              rules="required"
            >
              <b-form-input
                id="libelle"
                v-model="defaulLibelle"
                placeholder="Libellé"
                name="libelle"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Image d'illustration"
          >
            <b-button
              class="mr-2"
              @click="uploadFile()"
            >
              Charger une image
            </b-button>
            <div style="display: none">
              <b-form-file
                ref="flashfile"
                accept=".png,.jpg,.jpeg"
                name="piece_jointe"
                hidden
                @change="formatBase64File($event)"
              />
            </div>

          </b-form-group>
          <b-form-group
            label="Texte d'information"
          >
            <validation-provider
              #default="{ errors }"
              name="text_infos"
              rules="required"
            >
              <vue-editor
                v-model="defaultTextInfos"
                :editor-toolbar="toolbar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="fromRow"
            label="Statut"
          >
            <b-form-checkbox
              v-model="checked"
              checked="true"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span>{{ isChecked }}</span>
            </b-form-checkbox>
          </b-form-group>
        </b-form>
      </validation-observer>

    </b-modal>

    <b-modal
      id="modalApercu"
      v-model="showModalImage"
      centered
      size="l"
      title="Apercu"
      ok-title="Ok"
      ok-only
      @ok="closeImageModal()"
    >
      <b-img
        :src="dataModalImage"
        fluid-grow
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormInput, BFormGroup, BFormTextarea, BFormFile, BImg,
  BCard, BButton, BCol, BRow, BBadge, VBModal, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import {
  ValidationProvider, ValidationObserver, localize, validate,
} from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import { VueEditor } from 'vue2-quill-editor'
import {
  required, email, min,
} from '@validations'
import CustomFilter from './competences-fonctions/customFilter.vue'
// import ModalUpdate from './modalUpdate.vue'
localize('fr', fr)
export default {
  components: {
    BCard,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BBadge,
    BCol,
    BRow,
    BFormTextarea,
    BFormFile,
    BImg,
    VueGoodTable,
    CustomFilter,
    ValidationProvider,
    ValidationObserver,
    VueEditor,
    // ModalUpdate,
  },
  data() {
    return {
      showModalImage: false,
      dataModalImage: null,
      fromRow: false,
      rowId: '',
      defaulLibelle: '',
      defaultTextInfos: '',
      piece_jointe: null,
      modalShow: false,
      modalTitle: '',
      checked: false,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        ['link'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

        [{ size: ['small', 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ['clean'],
      ],
      columns: [
        {
          field: 'update',
          sortable: false,
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          field: 'apercu',
          label: 'Apercu',
          sortable: false,
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          label: 'Date de création',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSXXX',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          label: 'Libellé',
          field: 'libelle',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          label: 'Pièce jointe ?',
          field: 'hasFile',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
        {
          label: 'Statut',
          field: 'actif',
          type: 'boolean',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
      ],
      rows: [],
    }
  },
  computed: {
    isChecked() {
      return this.checked ? 'actif' : 'inactif'
    },
  },
  beforeMount() {
    this.$store.dispatch('flashinfo/getAllFlashinfos').then(response => {
      this.rows = response
    })
  },
  methods: {
    newFlashInfo() {
      this.$router.push({ name: 'admin-flashinfo-edit' })
    },
    closeImageModal() {
      this.showModalImage = false
    },
    openImage(imageData) {
      this.dataModalImage = imageData
      this.showModalImage = true
    },
    updateFile(name, files) {
      const [fileContent] = files
      this.piece_jointe = fileContent
    },
    alertModal(params) {
      this.$swal({
        title: params.title,
        text: params.text,
        icon: params.icon,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    submitForm() {
      const updateFlash = {
        text_infos: this.defaultTextInfos,
        libelle: this.defaulLibelle,
        actif: this.checked,
        piece_jointe: this.piece_jointe,
        id: this.rowId,
      }

      this.$refs.observer.validate().then(success => {
        if (success) {
          if (this.fromRow) {
            this.$store.dispatch('flashinfo/updateFlashInfo', updateFlash).then(res => {
              this.alertModal({ title: 'Mise à jour réussie', text: 'Les informations ont été mises à jour', icon: 'success' })
              this.$store.dispatch('flashinfo/getAllFlashinfos').then(response => {
                this.rows = response
                this.defaulLibelle = ''
                this.defaultTextInfos = ''
                this.checked = false
              })
            }).catch(error => {
              this.alertModal({ title: 'Erreur', text: error.message, icon: 'error' })
            })
          } else {
            this.$store.dispatch('flashinfo/createFlashInfo', body).then(res => {
              this.alertModal({ title: 'Ajout réussi', text: 'Une nouvelle compétence a été ajoutée', icon: 'success' })
              this.$store.dispatch('flashinfo/getAllFlashinfos').then(response => {
                this.rows = response
              })
            }).catch(error => {
              this.alertModal({ title: 'Erreur', text: error.message, icon: 'error' })
            })
          }
        }
      })
    },
    showModal(row) {
      this.piece_jointe = null
      if (row) {
        this.fromRow = true
        this.modalTitle = "Modification de l'info flash"
        this.rowId = row.id
        this.defaulLibelle = row.libelle
        this.defaultTextInfos = row.text_infos
        this.checked = row.actif
      } else {
        this.fromRow = false
        this.modalTitle = "Ajout d'une nouvelle info flash"
        this.rowId = null
        this.defaulLibelle = ''
        this.defaultTextInfos = ''
        this.checked = false
      }
      this.modalShow = !this.modalShow
    },
    onSearch() {
      const params = this.$store.getters['customFilter/getFilter']
      this.$store.dispatch('flashinfo/getAllFlashinfos', params).then(response => {
        this.rows = response
      })
    },
    uploadFile() {
      this.$refs.flashfile.$refs.input.click()
    },
    formatBase64File(event) {
      console.log('ici')
      // eslint-disable-next-line prefer-destructuring
      this.inputFile = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.inputFile)
      reader.onload = () => {
        this.imageURL = reader.result
        this.piece_jointe = reader.result
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#detailIcon {
  cursor: pointer
}
</style>
